* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.texts {
  margin: -30px;
}

.small {
  line-height: 22px;
  color: black;
  font-size: 20px;
}

.medium {
  line-height: 15px;
  color: white;
  font-size: 40px;
}

.big {
  line-height: 60px;
  font-size: 60px;
  color: #601e1d;

  padding-bottom: 20px;
}

.bigtext {
  line-height: 60px;
  font-size: 60px;
  color: #601e1d;
}

.bgg {
  position: absolute;
  width: 100%;

  height: 100vh;
  left: 0px;
  top: 0px;

  object-fit: cover;
  z-index: -1;
}

.logo {
  width: 100px;
  height: 100px;
}

.products {
  background-color: black;
}

.streaming {
  padding-top: 30px;
}

.mdsmall {
  line-height: 16px;
  color: black;
  font-size: 14px;
}

.mdmedium {
  line-height: 10px;
  color: white;
  font-size: 25px;
}

.mdbig {
  line-height: 60px;
  font-size: 60px;
  color: #601e1d;

  padding-bottom: 20px;
}

.mdbigtext {
  line-height: 40px;
  font-size: 40px;
  color: #601e1d;
}

.mdlogo {
  padding-top: 40px;
  width: 60px;
  height: 60px;
}

.mdstreaming {
  padding-top: 20px;
}

/* .register {
  justify-content: flex-end;
} */
